import http from '@/http';

const stateObj = {
  products: null,
  productGroups: [],
  foodProductsCount: 0
};

const mutations = {
  set_products(state, payload) {
    state.products = payload;
    state.foodProductsCount = payload.filter(
      (product) =>
        product.category !== 'misc' && product.available && product.isAdult
    ).length;
  },
  set_product_groups(state, payload) {
    state.productGroups = payload;
  }
};
const actions = {
  getProducts({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get('/products_2.0', { params: { all: true } })
        .then((response) => {
          commit('set_products', response.data.content);

          resolve(response);
        })
        .catch((err) => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  },
  getProductGroups({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get('/products_2.0', { params: { group: true } })
        .then((response) => {
          commit('set_product_groups', response.data.content);

          resolve(response);
        })
        .catch((err) => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  }
};

const getters = {
  productGroupsGetter: (state) => state.productGroups,
  getProducts: (state) => state.products,
  foodProductsCount: (state) => state.foodProductsCount
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
