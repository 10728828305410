import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import utils from '@/utils';
import forwards from '@/forwards';
import { find, startCase } from 'lodash/fp';

import mixpanel from 'mixpanel-browser';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      guest: true,
      showCommunity: true
    }
  },
  {
    path: '/kitten',
    name: 'kitten',
    component: () =>
      import(/* webpackChunkName: "kitten" */ '../views/Kitten.vue'),
    meta: {
      showCommunity: true,
      reviewType: 'kitten'
    }
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () =>
      import(/* webpackChunkName: "pricing" */ '../views/PricingCats'),
    meta: {
      showCommunity: true,
      reviewType: 'pricing'
    }
  },
  {
    path: '/about-us',
    name: 'about us',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/WhyChooseUs.vue'),
    meta: {
      showCommunity: true
    }
  },
  {
    path: '/food',
    name: 'the food',
    component: () =>
      import(/* webpackChunkName: "food" */ '../views/TheFood.vue'),
    meta: {
      showCommunity: true
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy policy',
    component: () =>
      import(/* webpackChunkName: "privacy" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/terms-conditions',
    name: 'terms conditions',
    component: () =>
      import(/* webpackChunkName: "terms" */ '../views/TermsConditions.vue')
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import(/* webpackChunkName: "faqs" */ '../views/Faqs.vue'),
    meta: {
      showCommunity: true
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () =>
      import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      requiresAuth: true
    },
    redirect: { name: 'personal details' },
    children: [
      {
        path: 'personal-details',
        name: 'personal details',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "personal" */ '../views/settings/PersonalDetails.vue'
            )
        },
        props: true
      },
      {
        path: 'billing-details',
        name: 'billing details',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "billing" */ '../views/settings/BillingDetails.vue'
            )
        },
        props: true
      },
      {
        path: 'security-details',
        name: 'security details',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "security" */ '../views/settings/SecurityDetails.vue'
            )
        },
        props: true
      },
      {
        path: 'cats-details',
        name: 'cats details',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "cats" */ '../views/settings/CatsDetails.vue'
            )
        },
        props: true
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      hideChat: true,
      requiresAuth: true
    }
  },
  {
    path: '/order-history',
    name: 'order history',
    component: () =>
      import(/* webpackChunkName: "order" */ '../views/OrderHistory.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/feeding-guidelines',
    name: 'feeding guidelines',
    component: () =>
      import(
        /* webpackChunkName: "feeding" */ '../views/FeedingGuidelines.vue'
      ),
    props: true,
    meta: {
      showCommunity: true
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: () =>
      import(/* webpackChunkName: "signup" */ '../views/Signup.vue'),
    props: true,
    meta: {
      guest: true,
      hideChat: true
    },
    children: [
      {
        path: 'createaccount',
        name: 'create account',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "create" */ '../views/account/create/CreateAccount.vue'
            )
        },
        props: true,
        meta: {
          hideChat: true,
          background: {
            class: 'text-blue-400',
            rotate: '-12'
          }
        }
      },
      {
        path: 'cats',
        name: 'cats',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "cats" */ '../views/account/create/Cats.vue'
            )
        },
        props: true,
        meta: {
          hideChat: true,
          background: {
            class: 'text-teal-200 ',
            rotate: '12'
          }
        }
      },
      {
        path: 'pricing',
        name: 'pricing plan',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "pricing" */ '../views/account/create/Pricing.vue'
            )
        },
        props: true,
        meta: {
          hideChat: true,
          background: {
            class: 'text-purple-200 ml-24 -mt-24',
            rotate: '0'
          }
        }
      },

      {
        path: 'delivery',
        name: 'delivery',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "delivery" */ '../views/account/create/Delivery.vue'
            )
        },
        meta: {
          hideChat: true,
          requiresAuth: true,
          background: {
            class: 'text-pink-400 ml-12 -mt-24',
            rotate: '-5'
          }
        }
      },
      {
        path: 'checkout',
        name: 'checkout',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "checkout" */ '../views/account/create/Checkout.vue'
            )
        },
        meta: {
          requiresAuth: true,
          background: {
            class: 'text-white',
            rotate: '0'
          }
        }
      }
    ]
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: () =>
      import(/* webpackChunkName: "subscribe" */ '../views/Subscribe.vue'),
    props: true,
    meta: {
      guest: true,
      hideChat: true
    },
    children: [
      {
        path: 'cats',
        name: 'subscribe cats',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "cats" */ '../views/account/create/Cats.vue'
            )
        },
        props: true,
        meta: {
          hideChat: true,
          requiresAuth: true,
          background: {
            class: 'text-teal-400 ml-24 mt-24',
            rotate: '0'
          }
        }
      },
      {
        path: 'pricing',
        name: 'subscribe pricing',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "pricing" */ '../views/account/create/Pricing.vue'
            )
        },
        props: true,
        meta: {
          requiresAuth: true,
          isSubscribe: false,
          hideChat: true,
          background: {
            class: 'text-purple-400 ml-24 mt-24',
            rotate: '0'
          }
        }
      },
      {
        path: 'delivery',
        name: 'subscribe delivery',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "delivery" */ '../views/account/create/Delivery.vue'
            )
        },
        meta: {
          requiresAuth: true,
          isSubscribe: true,
          background: {
            class: 'text-pink-400 ml-12',
            rotate: '-5'
          }
        }
      }
    ]
  },
  {
    path: '/adjust',
    name: 'adjust',
    component: () =>
      import(/* webpackChunkName: "adjust" */ '../views/Adjust.vue'),
    props: true,
    meta: {
      guest: true,
      hideChat: true
    },
    children: [
      {
        path: 'pricing/summary',
        name: 'adjust pricing summary',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "pricing" */ '../views/account/create/Pricing.vue'
            )
        },
        props: true,
        meta: {
          hideChat: true,
          requiresAuth: true,
          isSubscribe: true,
          isSummary: true,
          background: {
            class: 'text-white ml-24 mt-24',
            rotate: '0'
          }
        }
      },
      {
        path: 'pricing/:catId',
        name: 'adjust pricing cat',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "pricing" */ '../views/account/create/Pricing.vue'
            )
        },
        props: true,
        meta: {
          hideChat: true,
          requiresAuth: true,
          isSubscribe: true,
          background: {
            class: 'text-white ml-24 mt-24',
            rotate: '0'
          }
        }
      },
      {
        path: 'pricing',
        name: 'adjust pricing',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "pricing" */ '../views/account/create/Pricing.vue'
            )
        },
        props: true,
        meta: {
          hideChat: true,
          requiresAuth: true,
          isSubscribe: true,
          background: {
            class: 'text-white ml-24 mt-24',
            rotate: '0'
          }
        }
      },
      {
        path: 'new',
        name: 'adjust new',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "new" */ '../views/account/adjust/new.vue'
            )
        },
        props: true,
        meta: {
          hideChat: true,
          requiresAuth: true,
          isSubscribe: true
        }
      },
      {
        path: 'delivery',
        name: 'adjust delivery',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "delivery" */ '../views/account/create/Delivery.vue'
            )
        },
        meta: {
          hideChat: true,
          requiresAuth: true,
          isSubscribe: true,
          background: {
            class: 'text-pink-400 ml-12',
            rotate: '-5'
          }
        }
      }
    ]
  },
  {
    path: '/success',
    name: 'success',
    component: () =>
      import(/* webpackChunkName: "success" */ '../views/Success.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/auth/Login.vue'),
    meta: {
      guest: true
    }
  },

  {
    path: '/forgotten-password',
    name: 'forgotten password',
    component: () =>
      import(
        /* webpackChunkName: "forgotten" */ '../views/auth/ForgottenPassword.vue'
      ),
    meta: {
      guest: true
    }
  },
  {
    path: '/reset-password',
    name: 'reset password',
    component: () =>
      import(/* webpackChunkName: "reset" */ '../views/auth/ResetPassword.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/cancel',
    name: 'cancel',
    component: () =>
      import(/* webpackChunkName: "cancel" */ '../views/cancel/Cancel.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      hideChat: true
    },
    children: [
      {
        path: 'feedback',
        name: 'cancel feedback',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "feedback" */ '../views/cancel/CancelFeedback.vue'
            )
        },

        props: true
      },
      {
        path: 'confirm',
        name: 'cancel confirm',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "confirm" */ '../views/cancel/CancelConfirm.vue'
            )
        },
        props: true,
        meta: {
          hideChat: true
        }
      },
      {
        path: 'success',
        name: 'cancel success',
        components: {
          page: () =>
            import(
              /* webpackChunkName: "success" */ '../views/cancel/CancelSuccess.vue'
            )
        },
        props: true
      }
    ]
  },
  {
    path: '/l/:adId?',
    name: 'landing',
    component: () =>
      import(/* webpackChunkName: "landing" */ '../views/landing/Landing.vue'),
    meta: {
      guest: true,
      showCommunity: false,
      hideChat: true
    },
    beforeEnter: (to, from, next) => {
      if (to.params.adId) {
        utils.setAdID(to.params.adId);

        next();
        utils.sendAdEvent(to.params.adId);
      } else {
        next('/');
      }
    }
  },
  {
    path: '/how-we-doing',
    name: 'reviews',
    component: () =>
      import(/* webpackChunkName: "reviews" */ '../views/Reviews.vue'),
    meta: {
      requiresAuth: true
    }
  },
  { path: '*', redirect: { path: '/' } }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    if (
      (to.name === 'adjust pricing cat' ||
        to.name === 'adjust pricing summary') &&
      (from.name === 'adjust pricing cat' ||
        from.name === 'adjust pricing summary' ||
        from.name === 'adjust pricing')
    ) {
      return false;
    }

    return { x: 0, y: 0 };
  }
});

router.afterEach((to, from) => {
  mixpanel.track(
    `${from.name ? startCase(from.name) + ' --> ' : ''}${startCase(
      to.name
    )} Page`,
    {
      type: 'PV'
    }
  );
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  if (to.meta.hideChat) {
    document.body.classList.add('hide-mb');
  } else {
    document.body.classList.remove('hide-mb');
  }

  if (to.query.f) {
    const l = find(['id', to.query.f], forwards);
    if (l) {
      store.dispatch('sendMpEvent', {
        event: 'Foward To',
        properties: {
          location: to.name,
          forwardID: l.id,
          forwardName: l.name,
          forwardLink: l.link,
          type: 'EVT'
        }
      });
      window.location.href = l.link;
      return;
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    const loginpath = window.location.pathname + window.location.search;
    next({ name: 'login', query: { next: loginpath } });
  } else {
    next();
  }

  if (to.matched.some((record) => record.meta.guest)) {
    if (!store.getters.isLoggedIn) {
      next();
      return;
    } else {
      next({ name: 'dashboard' });
    }

    if (localStorage.getItem('signup')) {
      if (to.name === 'create account') {
        if (from.name === 'delivery') {
          router.go(-1);
          return;
        } else {
          router.go(1);
          return;
        }
      }
      next();
    } else {
      next({ name: 'dashboard' });
    }
  } else {
    next();
  }
});

export default router;
